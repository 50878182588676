import React from 'react';
import './button.css';
import logo_w2 from '../../assets/images/wtsp2.png' 
import call_icon from '../../assets/images/call_icon.png';


const Button = () => {


  return (
    <div className="scroll-to-top">

{/* button whatsApp */}
      <div className='div_button'>
          <a href="https://wa.me/+32493389823" className="whatsapp-button"><img src={logo_w2} alt="" /></a>
          <i>contactez-nous </i>
      </div>

      <div className='div_button_2'>
          <a href="tel:+32493389823" className="call_button"><img src={call_icon} alt="" /></a>
          <i>Appelez-nous </i>
      </div>
    </div>

  );
};

export default Button;
