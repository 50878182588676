import React from 'react';
import { Link } from 'react-router-dom';
import './error.css'

const ErrorPage = () => {
  return (
    <div >
      <div className='container'>
        <div className='div_error'>
              <h1>Error 404 - Page not found</h1>
      <p>Sorry, the page you are looking for could not be found.</p>
      <Link to="/">
        <button>Go to Home</button>
      </Link>
        </div>
      </div>
      
    </div>
  );
}

export default ErrorPage;


