import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './features/Home_page/Homepage';
import Button from './features/button/button';
import Navigationbar from './features/Nav/Nav_Bar';
import Footer from './features/Footer/footer';
import ErrorPage from './features/error_page/error';


const App = () => {
  return (
    <Router>
      <Routes>
        {/* Route pour la page d'accueil */}
        <Route path="/" element={<HomePageWithFooter />} />
        {/* Route pour la page d'erreur 404 */}
        <Route path="*" element={<ErrorPageWithFooter />} />
        {/* Cette route capture toutes les URL non trouvées et affiche l'erreur 404 */}
      </Routes>
    </Router>
  );
}

const HomePageWithFooter = () => {
  return (
    <>
      <Navigationbar />
      <div className='App'>
        <Button />
      </div>
      <HomePage />
      <Footer />
    </>
  );
}

const ErrorPageWithFooter = () => {
  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState(false);

  useEffect(() => {
    setIsHomePage(location.pathname === '/');
  }, [location.pathname]);

  return (
    <>
      <div className='App'>
        <ErrorPage />
      </div>
      <Footer style={!isHomePage ? { position: 'fixed', bottom: '0', width: '100%' } : {}} />
      {/* Appliquez un style pour le body uniquement si c'est la page d'erreur 404 */}
      {location.pathname !== '/' && <style>{`body { height: 100%; }`}</style>}
    </>
  );
}

export default App;
