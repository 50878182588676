import React from 'react';
import "./home_page.css";
import Carousel from '../carousel/carousel';
import img_map from "../../assets/images/Map Bruxelles.png"
import logo_w from "../../assets/images/whatsapp-svgrepo-com.svg"
import logo_mail from "../../assets/images/mail-open-alt-svgrepo-com.svg"
import video_presentation from "../../assets/videos/Video_MJP.mov"
import baignoire from "../../assets/images/baignoire.png"
import douche from "../../assets/images/douche.png"
import egout from "../../assets/images/egout.png"
import lavabo from "../../assets/images/lavabo.png"
import machine_laver from "../../assets/images/machine_laver.png"
import vaisselle from "../../assets/images/vaissaile.png"
import toilette from "../../assets/images/toilette.png"
import pic1 from "../../assets/images/photo_jo_1.jpg"
import pic2 from "../../assets/images/photo_jo_2.jpg"
import pic3 from "../../assets/images/photo_jo_3.jpg"
import pic4 from "../../assets/images/photo_jo_4.jpg"
import pic5 from "../../assets/images/photo_jo_5.jpg"
import pic6 from "../../assets/images/photo_jo_6.jpg"


const images = [ pic1, pic2, pic3, pic4, pic5, pic6]

function Home_page() {
  
  return (
    <div className="Home_page">
      <section>

      <div className="Titles" id="section1">
        <h1 >DÉBOUCHAGE DE CANALISATION PAR DES PROFESSIONNELS</h1>
      </div>
      <div className="presentation">
        <div className="content">
          <h2 className="subtitles">Qui sommes nous ?</h2>
          <p>
            Bienvenue chez <b>MJP Group</b>, votre partenaire de confiance
            pour tous vos besoins en débouchage. Nous sommes une équipe
            passionnée et dévouée, composée d'experts en plomberie et de
            professionnels du débouchage, prêts à résoudre vos problèmes de
            canalisations avec efficacité et professionnalisme.
          </p>
        </div>
        <div className="content">
          <h2 className="subtitles">Notre Mission</h2>
          <p>
            Notre mission principale est de fournir des services de débouchage
            de haute qualité, rapides et fiables. Nous comprenons l'importance
            cruciale d'avoir des canalisations et des égouts en parfait état de
            fonctionnement, c'est pourquoi nous nous engageons à offrir des
            solutions durables pour éliminer les obstructions, les bouchons et
            les problèmes de drainage. N'hésitez pas à nous contacter au: <a href="tel:+32493389823">+32 493 38 98 23</a> 
          </p>
        </div>
      </div>

      <div className='container_carousel'>
      <Carousel images={images}/>
      </div>

      </section>
      <section className="section_2" id="section2">
      {/* zone d'intervention */}
      <div className="Titles" >
        <h1>ZONE D'INTERVENTION</h1>
      </div>
      <div className="text_intervention">
        <h3>
          Notre entreprise opère dans toute la région de Bruxelles et ses
          environs, proposant des solutions de débouchage rapides, efficaces et
          durables. Nous nous engageons à être à votre service 24h/24 et 7J/7 . Notre équipe qualifiée est prête à intervenir à tout
          moment pour résoudre rapidement et efficacement tous vos problèmes de
          débouchage, assurant un service fiable et de qualité.
        </h3>
        <h3>Les communes d'intevention :</h3>
        <br />
        <h3 className="commune_name">
Anderlecht, Auderghem,
Berchem-Sainte-Agathe, Bruxelles-Ville,
Etterbeek, Evere,
Forest, Ganshoren,
Ixelles, Jette,
Koekelberg, Molenbeek-Saint-Jean,
Saint-Gilles, Saint-Josse-ten-Noode,
Schaerbeek, Uccle,
Watermael-Boitsfort,Woluwe-Saint-Lambert et Woluwe-Saint-Pierre
        </h3>
      </div>
      <br />
    <div className="img_map">
      <img src={img_map} alt="" />
    </div>
      </section>

      <div className="Presentation">
        <div className="Titre_presentation">
          <h1 className="Titre_presentation_1">Découvrer nos services de</h1>
          <h1 className="Titre_presentation_2">Débouchage de Canalisation</h1>
        </div>

        <div className="services">
      <div className="service-item">
        <div className="services_logo">
          <img src={toilette} alt="" />
        </div>
        <h2>WC</h2>
      </div>
      
      <div className="service-item">
        <div className="services_logo">
          <img src={egout} alt="" />
        </div>
        <h2> Egout</h2>
      </div>
      
      <div className="service-item">
        <div className="services_logo">
          <img src={lavabo} alt="" />
        </div>
        <h2>Evier/Lavabo</h2>
      </div>

      <div className="service-item">
        <div className="services_logo">
          <img src={douche} alt="" />
        </div>
        <h2>Douche et Spihon</h2>
      </div>

      <div className="service-item">
        <div className="services_logo">
          <img src={baignoire} alt="" />
        </div>
        <h2>Salle de bains</h2>
      </div>

      <div className="service-item">
        <div className="services_logo">
          <img src={machine_laver} alt="" />
        </div>
        <h2>Machine à Laver</h2>
      </div>

      <div className="service-item">
        <div className="services_logo">
          <img src={vaisselle} alt="" />
        </div>
        <h2>Lave vaisselle</h2>
      </div>

      {/* Ajoutez d'autres divs pour chaque service */}

    </div>

            <div className="section_video">
              <video width="65%" height="auto" controls >
                <source src={video_presentation} />
              </video>
              
            </div>
      </div>

      <div className="Titles" id="section3">
        <h1>NOS TARIFS</h1>
  </div>
      <div className="section_card">
        <div className="card">
          <div className="card_title">
            <h2>DÉBOUCHAGE MANUEL</h2>
          </div>
          <div className="card_price">
            <h1>APD: 130 € /TTC</h1>
          </div>
          <div className="card_info">
            <p>Toilettes bouchées</p>
            <p>Siphon</p>
            <p>Douche / baignoire</p>
            <p>Lave-vaisselle</p>
            <p>Evacuation</p>
            <i className="explication">Pour ce forfait nous utilisons selon votre installation un déboucheur révolver basse pression, un furet mécanique (professionnel).</i>
          </div>
        </div>

        <div className="card">
          <div className="card_title">
            <h2>PASSAGE CAMÉRA</h2>
          </div>
          <div className="card_price">
            <h1>APD: 120 € /TTC</h1>
          </div>
          <div className="card_info">
            <p>Inspection canalisation sanitaire</p>
            <p>Inspection regard égouts</p>
            <br />
            <i className="explication">Cette intervention consiste à l'usage d'une caméra endoscopique d'une longueur de 30 mètres avec enregistrement vidéo possible. Système agréé par les assurances.</i>
          </div>
        </div>

        <div className="card">
          <div className="card_title">
            <h2>HYDROCURAGE</h2>
          </div>
          <div className="card_price">
            <h1>APD: 240 € /TTC</h1>
          </div>
          <div className="card_info">
            <p>Nettoyage canalisation</p>
            <p>Débouchage</p>
            <p>Détartrage</p>
            <p>Curage</p>
            <h3 style={{ color: '#ff4757' }}><i>Passage caméra offert</i></h3>
          </div>
        </div>
      </div>

      <div className="advise">
        <div className="red_card">
          <h3 style={{color: 'red'}}> REMARQUE: Le prix des débouchages des conduits principaux sont à déterminer par le professionnel via WhatsApp ou  sur place, et les prix mentionnés si dessus peuvent varier selon le temps resté sur le chantier.</h3>
        </div>
      </div>
{/* section de contact */}
          <div className="Titles" id="section4">
            <h1> NOUS CONTACTER </h1>
          </div>
        <div className="sec_contact">
          
          <div className="card_contact">
            <div className="contact">
              <span> <img className="svg_size2" src={logo_w} alt=""/></span>
            </div>
            <a href="https://wa.me/+32493389823">Chat on whatsapp</a>
            
          </div>

          <div className="card_contact" >
            <div className="contact">
              <span> <img className="svg_size2" src={logo_mail} alt=""/></span>
            </div>
            <a href="mailto:mjp-groupe@hotmail.Com">Send email</a>
          </div>
      </div>
    </div>
  );
}

export default Home_page;
