import React from 'react';
import './footer.css'; // Assurez-vous d'avoir un fichier CSS pour styliser votre pied de page

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 MJP GROUPE. Tous droits réservés.</p>
        <p>Contactez-nous : <a href="mailto:mjp-groupe@hotmail.Com">mjp-groupe@hotmail.com</a></p>
      </div>
    </footer>
  );
};

export default Footer;
