import React from 'react'
import './Nav_bar.css'
import logo_w from '../../assets/images/whatsapp-svgrepo-com.svg'
import logo_time from '../../assets/images/time-twenty-four-svgrepo-com.svg'
import logo_check from '../../assets/images/check-square-svgrepo-com.svg'
import logo_site from '../../assets/images/Logo_site.png'

function Nav() {

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return(
    <div className="Nav_bar">
        <div className='nav_info'>
            <ul>
                <li> <img className='svg_size' src={logo_check} alt="" /> Devis gratuit</li>
                <li><img className='svg_size' src={logo_time} alt="" /> Disponible 7J/7 et 24h/24 </li>
                <li>
                  <img className='svg_size' src={logo_w} alt="" />
                  <a href="tel:+32493389823">+32 493 38 98 23</a>
                </li>

            </ul>
        </div>
        <div className='Nav_2'>
          <div className='logo_box'>
            <img src={logo_site} alt="Logo_site" />
          </div>
          <div className='nav_links'>
            <ul>
              <li><a href="#section1" onClick={() => scrollToSection('section1')}>Nos services</a></li>
              <li><a href="#section2" onClick={() => scrollToSection('section2')}>Zones d'intervention</a></li>
              <li><a href="#section3" onClick={() => scrollToSection('section3')}>Nos tarif</a></li>
              <li><a href="#section4" onClick={() => scrollToSection('section4')}>Nous contacter</a></li>
            </ul>
          </div>
        </div>


    </div>
  )

}

export default Nav