import React, { useState, useEffect } from 'react';
import './carousel.css';

const Carousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 8000); // Change every 8 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="carousel">
      {images.map((image, index) => (
        <div key={index} className={index === currentImageIndex ? 'slide active' : 'slide'}>
          <img src={image} alt={`Slide ${index}`} className="slide-image" />
          <div className="overlay">
            <h2>DÉBOUCHAGE BRUXELLES</h2>
            <hr className='hr_carousel' />
            <br />
            <h3>Entreprise spécialisée dans le </h3>
            <h3>débouchage de canalisation</h3>
            <h4><i>Pour toute demande d'intervention</i></h4>
            <h4><i>Veuillez nous contacter au:</i></h4>
            <br />
            <button><a href="tel:+32493389823">+32 493 38 98 23</a></button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Carousel;
